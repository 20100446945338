import { scrollTrigger } from '../../modules/scrollTrigger.js';
import { autoClamper } from '../../modules/autoClamper.js';
import { pageTop } from '../../modules/pageTop.js';
import { scrollReading } from '../../modules/scrollReading.js';
import { SimpleModal } from '../../modules/SimpleModal.js';
import { Splide } from 'npm:@splidejs/splide@4.1.4';

// 複数行を丸める
new autoClamper('.js-auto-clamp')

/**
 * モノクロのクロスフェード
 */
setTimeout(function () {
	const crossfade_els = document.querySelectorAll('.js-mono-crossfade');
	crossfade_els.forEach(elem => {
		elem.classList.add('is-active');
	})

	const text_fadein_els = document.querySelectorAll('.js-text-fadein');
	text_fadein_els.forEach(elem => {
		elem.classList.add('is-visible');
	})

}, 300);

/**
 * スクロールアニメーション
 */
// テキストを1文字ずつ表示する
new scrollTrigger('.js-scrl-text-fade-up', {
	cb: function (el) {
		const main_el = el.querySelector('.js-scrl-text-fade-up__main');
		// span要素の作成
		const text = main_el.textContent;
		main_el.textContent = '';
		let is_long_text = false;
		if (10 < text.length) {
			is_long_text = true;
		}
		for (let i = 0; i < text.length; i++) {
			let textbox = '';
			let word = text[i];
			let attr_class = '';
			if (word === ' ') {
				word = '&nbsp;';
				if (is_long_text) {
					attr_class = ' class="is-nl2br"';
				}
			}
			//const delay = EaseInEaseOut(i / text.length);
			//textbox = '<span' + attr_class + ' style="animation-delay:' + delay + 's;">' + word + '</span>';
			if (i < 10) {
				textbox = '<span' + attr_class + ' style="animation-delay:.' + i + 's;">' + word + '</span>';
			} else {
				const n = i / 10;
				textbox = '<span' + attr_class + ' style="animation-delay:' + n + 's;">' + word + '</span>';
			}
			main_el.insertAdjacentHTML('beforeend', textbox)
		}
		el.classList.add('is-enabled');
	}
})
// クロスフェード
new scrollTrigger('.js-image-crossfade', {
	cb: function (el) {
		const crossfade_els = el.querySelectorAll('.is-mono');
		crossfade_els.forEach((elem) => {
			elem.classList.add('is-active');
		});
	},
})

// トップページのスライダー
const topSlider = document.querySelector('#main-slider');
if (topSlider) {
	const slideElements = topSlider.querySelectorAll('.splide__slide');
	const slideCount = slideElements.length;
	if (1 < slideCount) {
		const main_splide = new Splide('#main-slider', {
			type: 'loop',
			padding: '0',
			focus: 'center',
			updateOnMove: true,
			autoWidth: true,
			pagination : false,
			arrows     : true,
			gap: 0,
			mediaQuery: 'min',
			breakpoints: {
				1280: {
					fixedWidth      : 550,
					fixedHeight     : 275,
					arrows: false
				}
			}
		});
		const thumbnails_splide = new Splide( '#thumbnail-slider', {
			rewind          : true,
			fixedWidth      : '32%',
			isNavigation    : true,
			gap             : 5,
			pagination      : false,
			cover: true,
			arrows     : false,
			dragMinThreshold: {
			  mouse: 4,
			  touch: 10,
			},
			mediaQuery: 'min',
			breakpoints: {
				1280: {
					fixedWidth      : 180,
					fixedHeight     : 93
				}
			}
		} );
		main_splide.sync(thumbnails_splide);
		main_splide.mount();
		thumbnails_splide.mount();
	}
}

// セラピストの写真スライダー
const therapistPhotoSlider = document.querySelector('.js-therapist-profile-photo-slider');
if (therapistPhotoSlider) {
	const slideElements = therapistPhotoSlider.querySelectorAll('.splide__slide');
	const slideCount = slideElements.length;
	if (1 < slideCount) {
		const main_splide = new Splide('.js-therapist-profile-photo-slider', {
			type: 'loop',
			padding: '0',
			focus: 'center',
			updateOnMove: true,
			pagination : false,
			arrows     : false,
			gap: '0',
			mediaQuery: 'min',
			breakpoints: {
				1280: {
					arrows: false
				}
			}
		});
		const thumbnails_splide = new Splide( '.js-therapist-profile-photo-thumbnail-slider', {
			rewind          : true,
			fixedWidth      : 42,
			fixedHeight     : 42,
			isNavigation    : true,
			gap             : 8,
			pagination      : false,
			cover: true,
			arrows     : false,
			dragMinThreshold: {
			  mouse: 4,
			  touch: 10,
			},
			mediaQuery: 'min',
			breakpoints: {
				1280: {
					fixedWidth      : 52,
					fixedHeight     : 52,
				}
			}
		} );
		main_splide.sync(thumbnails_splide);
		main_splide.mount();
		thumbnails_splide.mount();
	} else {
		therapistPhotoSlider.classList.remove('splide');
	}
}

// ページトップ
new pageTop()

// ajax+jsonによる追加読込
new scrollReading('.js-scrl-reading-point', {
	threshold: '0.12', cb: function (el) {
		new scrollTrigger('.js-image-crossfade', {
			cb: function (el) {
				const crossfade_els = el.querySelectorAll('.is-mono');
				crossfade_els.forEach((elem) => {
					elem.classList.add('is-active');
				});
			},
		})
	}
})

/**
 * ツイッターの遅延読み込み
 */
new scrollTrigger('.js-tw-container', {
	cb: function (el) {
		!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+"://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");
	}
})

/**
 * スマホ メニューアクティベート
 */
// チェックボックス要素を取得
const asideMenuOpenCheckbox = document.getElementById('AsideMenuOpen');

// チェックボックスの状態が変更されたときのイベントリスナーを追加
asideMenuOpenCheckbox.addEventListener('change', function() {
	// チェックされている場合は.is-activeを付与、そうでない場合は削除
	if (this.checked) {
		this.classList.add('is-active');
	}
});

// モーダル
new SimpleModal('.js-modal');